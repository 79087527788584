import React, { useState } from 'react';
import { Mic, MicOff } from 'lucide-react';
import { ScaleLoader } from 'react-spinners';

const MuteButton = ({ label, onClick, isLoading, disabled, style }) => {
  const [isMuted, setIsMuted] = useState(label === "Unmute");

  const opacity = disabled ? 0.75 : 1;
  const cursor = disabled ? "not-allowed" : "pointer";

  const buttonStyle = {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: isMuted ? '#FF453A' : '#4CD964',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    opacity,
    cursor,
    ...style,
  };

  const iconStyle = {
    color: 'white',
    strokeWidth: 2,
  };

  const handleClick = () => {
    setIsMuted(!isMuted);
    onClick();
  };

  const Contents = isLoading ? (
    <ScaleLoader
      color="#FFF"
      height={15}
      width={2}
      margin={2}
      loading={true}
      css={{ display: "block" }}
    />
  ) : (
    isMuted ? (
      <MicOff style={iconStyle} size={40} />
    ) : (
      <Mic style={iconStyle} size={40} />
    )
  );

  return (
    <button style={buttonStyle} onClick={handleClick} disabled={disabled}>
      {Contents}
    </button>
  );
};

export default MuteButton;